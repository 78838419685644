@import "variables";

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .right {
    flex: 1 1 auto;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
