.ConceptButton {
  // position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: stretch;
  // height: 100%;
}

.button {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
}
