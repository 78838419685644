.menu {
  display: flex;
  align-items: center;
}

.button {
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  width: 0;
  left: 0;
  overflow: hidden;
  visibility: none;
}
