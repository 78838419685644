@use "sass:math";
@import "variables";

.editor {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;

  .aceEditor {
    width: 100% !important;
  }

  .lock {
    position: absolute;
    top: 5px;
    right: $space;
    z-index: 1;
  }
}

.marker {
  position: absolute;
  border-bottom: 1px dashed red;
}
