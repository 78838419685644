@use "sass:math";
@import "variables";

.dialog {
  padding: $space * 2;

  form {
    display: flex;
    flex-direction: column;

    button {
      margin: $space auto auto;
      width: 75px;
    }
  }

  .btn_row {
    margin-top: $space;
    display: flex;
    justify-content: space-evenly;

    button {
      width: 75px;
    }
  }
}
