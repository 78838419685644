.avatar {
  display: flex;
  align-items: center;
}

.label {
  padding-right: 0.5rem;
}

.username {
  font-size: 0.9rem;
  text-align: right;
}

.role {
  font-size: 0.6rem;
  text-align: right;
}
