.openParanodeTable {
  padding: 1rem;
  text-align: center;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.table {
  // width: 100%;
  // height: 100%;
  margin: 1rem;
}

.header {
  font-weight: 700;
}
