@use "sass:math";
@import "variables";
.main {
  max-width: 800px;
  margin: auto;
  padding: 1rem;
}

.back {
  margin-right: 1rem;
  text-decoration: none;
}
