.label {
  font-style: italic;
  font-size: 0.9rem;
  // font-weight: 500;
}

.script {
  padding-top: 0.25rem;
  font-size: 0.85rem;
  font-family: "Courier New", Courier, monospace;
}
