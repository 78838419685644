.label {
  display: flex;
  justify-content: flex-start;
}

.tags {
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
}
