@import "variables";

.versions {
  padding: 0.5rem;
  position: relative;
  overflow-y: auto;
  flex: 1 1 100%;
}

.description {
  font-style: italic;
}

.newVersion {
  font-weight: bold;
}

.content {
  position: absolute;
  inset: 0;
}

.version {
  color: $text_light;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
}

.versionLabel {
  padding-right: 0.5rem;
}

.noVersionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noVersion {
  padding: 0.5rem;
  text-align: center;
  color: $text_light;
  font-size: 0.9rem;
}
