@import "variables";

.ActivityBar {
  flex: 0 0 55px;
  background-color: $background_hue1_light;
  border-right: 1px solid $border_light;
  color: $text_light;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px 0px;
}

.ActivityButton {
  color: $text_hue1_light !important;

  &.active {
    color: $text_hue2_light !important;
  }
}

.Activity {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &.active::before {
    display: block;
    content: " ";
    width: 4px;
    background: $text_hue2_light;
    position: absolute;
    top: 7px;
    bottom: 7px;
    left: 0;
  }
}
