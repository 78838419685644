// Colors
$primary: #bbbaba;
$primary-light: #e8e8e8;
$primary-dark: #969696;

$space: 20px;

// Breakpoints
$sm: 400px;
$md: 600px;
$lg: 900px;
$xl: 1300px;
$xxl: 1600px;

// https://blog.atom.io/2015/02/18/one-themes.html
$background_light: #eaeaeb;
$background_hue1_light: #fafafa;
$border_light: #e0e0e1;
$text_light: #464749;
$text_hue1_light: #9d9e9f;
$text_hue2_light: #1b1d20;

$error: #ff1818;
