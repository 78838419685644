@import "variables";

.expand {
  flex: 1 1 auto;
}

.StatusBar {
  height: 30px;
  flex: 0 0 30px;
  display: flex;
  align-items: center;
  background-color: $background_light;
  border-top: 1px solid $border_light;
  color: $text_light;

  font-size: 0.8rem;

  .item {
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }

  .file {
    @extend .item;
    @extend .expand;
  }

  .compilation {
    @extend .item;
    min-width: 200px;
  }

  .label {
    padding-left: 0.25rem;
    font-size: 0.95rem;
    line-height: 0.5rem;

    &.active {
      color: $text_hue2_light;
    }
  }

  .button {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
    color: $text_light;

    .icon {
      font-size: 1.1rem;
    }

    .label {
      padding-left: 0.25rem;
      font-size: 0.95rem;
      line-height: 0.5rem;

      &.active {
        color: $text_hue2_light;
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
}

.right {
  justify-content: flex-end;
}

.stats {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  color: $text_light;

  .icon {
    font-size: 1.1rem;
  }
}

.connectivity {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  color: $text_light;

  .icon {
    font-size: 1.1rem;
  }
}

.build {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.buildButton {
  cursor: pointer;
  color: $background_light;
  background: $text_light;
  border: 1px solid $text_light;
  padding: 0 0.1rem;
  border-radius: 0.15rem;
}
