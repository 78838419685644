.Menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;

  border-radius: 0.2rem;

  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Item {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0.3rem 0.5rem;

  flex: 1 1 100%;
}

.label {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  justify-content: flex-start;
  text-align: left;
}

.icon {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.script {
  padding-left: 0.5rem;
  font-family: "Courier New", Courier, monospace;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.iconButton {
  cursor: pointer;
}

.close {
  padding: 0.5rem;
  text-align: right;
}

.closeButton {
  font-size: 0.9rem;
  opacity: 0.6;
  cursor: pointer;
  background-color: transparent;
}
