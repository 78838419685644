.title {
  display: inline-block;
  padding: 0.3rem 0.6rem;
  background-color: #1976d2;
  color: white;
  font-weight: 200;

  .editor {
    font-weight: 700;
  }
}

.brand {
  padding-bottom: 2rem;
  text-align: center;
  font-size: 1.5rem;
}
