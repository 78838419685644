@use "sass:math";
@import "variables";

.ConceptsPanel {
  word-wrap: break-word;

  .accordions {
    margin-top: math.div($space, 2);

    .element_title div {
      span {
        margin-right: $space;
      }
    }

    .element_details div:not(:last-of-type) {
      margin-bottom: math.div($space, 2);
    }
  }

  .no_result {
    margin-left: 18px;
  }
}

.item {
  display: flex;
  align-items: flex-start;
  text-align: left;
}

.moreButton {
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
}
