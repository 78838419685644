@import "variables";

.BottomPanelContainer {
  position: relative;
}

.resizeBar {
  position: absolute;
  z-index: 1000;
  $height: 5px;
  top: -$height;
  height: $height;
  left: 0;
  right: 0;
  cursor: row-resize;

  &:hover {
    background-color: $text_hue1_light;
  }
}

.BottomPanel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // flex: 1 1 auto;
  border-top: 1px solid $border_light;
  background-color: $background_hue1_light;
  color: $text_light;
  display: flex;
  flex-direction: column;

  .header {
    border-bottom: 1px solid $border_light;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex: 0 0 auto;
    user-select: none;
  }

  .content {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .tabs {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
  }

  .tab {
    display: flex;
    align-items: center;

    cursor: pointer;
    color: $text_light;
    background-color: transparent;
    padding: 0.2rem 1rem;
    text-transform: uppercase;
    font-size: 0.9rem;
    border-right: 1px solid $border_light;

    position: relative;

    &.active {
      color: $text_hue2_light;
    }

    &.active::before {
      display: block;
      content: " ";
      height: 2px;
      background: $text_hue2_light;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.item {
  font-size: 0.9rem;
  padding: 0.1rem 0.5rem;
}

.indicators {
  margin-left: 0.4rem;
}

.button {
  cursor: pointer;
  color: $text_light;
  margin-right: 0.7rem;
  margin-left: 0.2rem;
}

.buttons {
  display: flex;
  align-items: center;
}
