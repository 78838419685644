@use "sass:math";
@import "variables";

.SideBar {
  background-color: $background_light;
  border-right: $border_light 1px solid;
  flex: 0 0 300px;
  display: flex;
  align-items: stretch;
}
