@use "sass:math";
@import "variables";

.SearchBar {
  display: flex;

  .input {
    flex: 1 1 auto;
    margin-right: 0.5rem;
  }

  .select {
    flex: 0 0 auto;
  }
}

.onlyCurrentFile {
  padding-top: 1rem;
  font-size: 0.8rem;
}
