@use "sass:math";
@import "variables";

.layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.Editor {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  .main {
    flex: 1 1 auto;
    position: relative;

    display: flex;
    flex-direction: column;
  }

  .center {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .create_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $space;
    margin-top: math.div($space, 2);

    svg {
      cursor: pointer;
    }
  }
}

.back {
  margin-right: 1rem;
  text-decoration: none;
}

.no_file {
  text-align: center;
  padding-top: $space;
  flex: 1 1 auto;
}
