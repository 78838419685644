@import "variables";

.search {
  border-bottom: 1px solid $border_light;
  padding: 0.5rem;
}

.results {
  flex: 1 1 0%;
  position: relative;
}

.resultsInner {
  position: absolute;
  overflow-y: scroll;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 0.5rem;
}

.sorted {
  font-size: 0.8rem;
  color: $text_light;
}
