.Table {
  border: 4px solid black;
  margin-bottom: 2rem;
}

.caption {
  border: 4px solid black;
  border-bottom: 0;
  background-color: #eaeaeb;
}

tbody + thead {
  border-top: 4px solid black;
}

.th {
  background-color: #eaeaeb;
}

.td,
.th {
  border: 1px solid black;
  position: relative;
}

.td {
  vertical-align: middle;
}
