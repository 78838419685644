@import "variables";

.header {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid $border_light;
  flex: 0 0 auto;

  /* TODO: use classname */
  * + * {
    margin-left: 0.5rem;
  }

  svg {
    cursor: pointer;
  }
}

.title {
  text-transform: uppercase;
  color: $text_light;
  font-size: 0.8rem;
  flex: 1 1 auto;
  font-weight: bold;
}
