.Table {
  border: 4px solid black;
  margin-bottom: 2rem;
}

.caption {
  // border: 4px solid black;
  // border-bottom: 0;
}

tbody + thead {
  border-top: 4px solid black;
}

.td,
.th {
  border: 1px solid black;
  position: relative;
}

.td {
  vertical-align: middle;
}

.TableItem {
  min-width: 7rem;
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  align-items: center;
  text-align: center;
}

.button {
  padding: 1rem;
  position: relative;
  width: 100%;
  text-align: center;
}

.link {
  position: relative;
  width: 100%;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
