.TableItem {
  min-width: 7rem;
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  text-align: center;
}

.button {
  padding: 1rem;
  position: relative;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.caption {
  padding: 1rem;
  position: relative;
  width: 100%;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.buttonIcon {
  display: inline-block;
}

.header {
  font-weight: bold;
}

.link {
  position: relative;
  width: 100%;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
