.Breadcrumb {
  display: flex;

  padding-bottom: 2rem;
  font-weight: bold;
  font-style: italic;
}

.chevron {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
