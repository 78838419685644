@import "variables";

/* Reset from https://www.css-reset.com */
* {
  vertical-align: baseline;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* End Reset */

/* Dark Mode */
//:root {
//  filter: invert(0.95);
//}

.app-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.page-title {
  margin: $space 0;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}
