@import "variables";

.erroneous_file {
  div.MuiTreeItem-label {
    border-bottom: red 2px dotted;
    width: max-content;
    padding: 0;
  }
}

.MuiTreeItem-label {
  width: max-content;
  padding: 0 !important;
}

.drag-over {
  background-color: $primary-light;
}

.file-item {
  display: flex;
  justify-content: flex-start;
  user-select: none;

  .MuiTreeItem-content {
    padding-left: 0;
    margin-left: 2px;
    width: max-content;
    justify-content: start;

    .MuiTreeItem-iconContainer {
      display: none;
    }
  }

  svg {
    opacity: 0.1;
    cursor: move;
    //margin-left: -5%;

    &:hover {
      opacity: 0.6;
    }
  }
}
