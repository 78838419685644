@import "variables";

.topBar {
  display: flex;
  align-items: stretch;
  height: 75px;
  background: $background_light;
  border-bottom: 1px solid $border_light;

  color: $text_light;

  // div {
  //   display: flex;
  //   align-items: center;

  //   img {
  //     width: 60px;
  //     margin-right: $space;
  //     cursor: pointer;

  //     &:hover {
  //       filter: invert(0.4);
  //     }
  //   }
  // }

  // a {
  //   color: inherit;
  //   text-decoration: none;
  //   cursor: pointer;
  // }

  // .breadcrumb {
  //   font-size: 1.3rem;

  //   a:hover {
  //     text-decoration: underline;
  //   }
  // }
}

.left {
  flex: 0 0 505px;
  display: flex;
  align-items: flex-start;
  width: 355px;
  padding: 0.75rem 1rem;
}

.align {
  display: flex;
  align-items: center;
}

.right {
  flex: 0 0 150px;
  display: flex;
  justify-content: right;
  align-items: flex-start;
  padding: 0.75rem 1rem;
}

.center {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0.75rem 1rem;
}

.brand {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
}

.logo {
  height: 30px;
}

.title {
  margin-left: 0.5rem;
  padding: 0.3rem 0.6rem;
  background-color: #1976d2;
  color: white;
  font-weight: 200;

  .editor {
    font-weight: 700;
  }
}
