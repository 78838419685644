.caption {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex: 1 1;
  background-color: #eaeaeb;
}

.Table {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  button {
    height: 100%;
    width: 100%;
  }
}

.captionTop {
  @extend .caption;
  border-bottom: 4px solid black;
}

.captionLeft {
  @extend .caption;
  border-right: 4px solid black;
}

.vertical {
  flex-direction: column;
}

.td {
  flex: 2 2;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  &.vertical:not(:last-child) {
    border-bottom: 1px solid black;
  }

  &.horizontal:not(:last-child) {
    border-right: 1px solid black;
  }
}
